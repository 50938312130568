<template>
  <div class="main-container home" v-if="!pagePadding">
    <Header
      :isMobile="isMobile"
      @navRightClick="newSession"
      @changeSession="changeSession"
      :currentSessionId="currentSessionId"
      :lastUpdatedSession="lastUpdatedSession"
    />
    <div
      class="top-bot-avatar"
      :class="
        (avatarFixed ? 'show ' : ' ') +
        (sending || messages.length == 0 ? '' : 'stop')
      "
    >
      <img :src="botInfo.avatar" />
    </div>
    <div class="home-main">
      <div class="home-main-side" v-if="!isMobile">
        <van-button
          type="primary"
          class="new-session-btn"
          round
          icon="plus"
          @click="newSession"
          >新建对话</van-button
        >
        <SessionList
          @changeSession="changeSession"
          :currentSessionId="currentSessionId"
          :lastUpdatedSession="lastUpdatedSession"
        />
      </div>
      <div class="home-main-content">
        <div
          class="message-scroll-box"
          id="message-scroll-box"
          @scroll="debounceHandleScroll"
        >
          <div class="message-list">
            <div id="welcome-msg" class="chat-item-wrap">
              <div class="bot-avatar-box" id="bot-avatar-box">
                <div
                  class="bot-avatar"
                  :class="sending || messages.length == 0 ? '' : 'stop'"
                >
                  <img :src="botInfo.avatar" />
                </div>
              </div>
              <div
                class="chat-item body_left"
                v-for="(item, i) in welcomeMsg"
                :key="'welcomemsg_' + i"
              >
                <div class="text" v-if="item.answerType == 0">
                  <span
                    v-if="item.textContent"
                    v-html="replaceAnswerText(item.textContent)"
                  ></span>
                </div>
                <div class="image" v-else-if="item.answerType == 1">
                  <van-image :src="item.image.url" fit="contain" />
                </div>
                <div v-else-if="item.answerType == 2" class="article">
                  <a :href="item.article.url" target="_blank">
                    <div class="article-title">{{ item.article.title }}</div>
                    <div class="article-description">
                      <span>{{ item.article.description }}</span>
                      <van-image :src="item.article.picUrl" fit="contain" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- </template> -->
            <div
              v-for="(item, i) in messages"
              :key="'msg_' + item.id + '_' + i"
              :id="'msg_' + item.id"
              class="chat-item-wrap"
            >
              <div class="chat-item body_right">
                <div class="text">
                  <span v-html="replaceAnswerText(item.askText)"></span>
                </div>
              </div>
              <template v-if="item.responses && item.responses.length > 0">
                <div
                  class="chat-item body_left"
                  v-for="(res, j) in item.responses"
                  :key="item.id + '_' + j"
                >
                  <div class="text" v-if="res.answerType == 0">
                    <span
                      v-html="replaceAnswerText2(res.textContent)"
                      class="markdown"
                    ></span
                    ><span class="typer-cursor" v-if="item.pendding"></span>
                  </div>
                  <div
                    class="image"
                    v-else-if="res.answerType == 1"
                    @click="imagePreview(res.image.url)"
                  >
                    <van-image :src="res.image.url" fit="contain" />
                  </div>
                  <div v-else-if="res.answerType == 2" class="article">
                    <a :href="res.article.url" target="_blank">
                      <div class="article-title">{{ res.article.title }}</div>
                      <div class="article-description">
                        <span>{{ res.article.description }}</span>
                        <van-image :src="res.article.picUrl" fit="contain" />
                      </div>
                    </a>
                  </div>
                </div>
              </template>
              <div v-else class="chat-item body_left">
                <span class="spinner"
                  ><i></i><i class="bounce1"></i><i class="bounce2"></i
                ></span>
              </div>
              <div class="chat-footer">
                <van-button
                  hairline
                  size="mini"
                  icon="clear"
                  @click="cancel(i)"
                  round
                  v-if="item.pendding"
                  >停止输出</van-button
                >
                <template v-else>
                  <van-button
                    plain
                    hairline
                    type="primary"
                    :icon="'good-job' + (item.goodOrBad == 1 ? '' : '-o')"
                    size="mini"
                    @click="thumbUp(item.id, item.goodOrBad, i)"
                    :disabled="item.goodOrBad == -1"
                    round
                  ></van-button
                  ><van-popover
                    placement="right-end"
                    trigger="manual"
                    :show="feedbackMsgId == item.id"
                    @close="feedbackMsgId = -1"
                  >
                    <MessageFeedbackForm
                      @close="feedbackMsgId = -1"
                      v-if="feedbackMsgId == item.id"
                      :messageId="item.id"
                      :index="i"
                      @success="thumbsDownSuccess" />
                    <template #reference
                      ><van-button
                        plain
                        hairline
                        type="primary"
                        :icon="'good-job' + (item.goodOrBad == -1 ? '' : '-o')"
                        size="mini"
                        class="no-good"
                        @click="feedbackMsgId = item.id"
                        :disabled="item.goodOrBad != 0"
                        round
                      ></van-button></template
                  ></van-popover>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="speech-recognition-wrap" v-if="isMobile && isRecording > 0">
          <div class="recognition-result-wrap">
            <div class="recognition-result-box">
              <van-loading color="#1989fa" v-if="isRecording < 2" />
              <template v-else>
                <div class="recognition-result-text">
                  {{ messageTxt }}
                </div>
                <canvas id="canvas" width="160" height="60"></canvas>
              </template>
            </div>
          </div>
          <div class="cancel-btn-wrap">
            <div
              class="btn-item"
              :class="cancelOrEditWhenRecording == 1 ? 'active' : ''"
            >
              <p>取消</p>
              <van-icon name="cross" />
            </div>
            <div
              class="btn-item"
              :class="cancelOrEditWhenRecording == 2 ? 'active' : ''"
            >
              <p>编辑</p>
              <van-icon name="edit" />
            </div>
          </div>
          <p class="tips">松手发送，上划取消/编辑</p>
        </div>
        <div class="mobile-input-wrap" v-if="isMobile">
          <div class="mobile-text-input-wrap" v-if="textOrVoice == 0">
            <div class="record-btn" @click="changeTextOrVoice">
              <img src="../assets/microphonepng.png" />
            </div>
            <van-field
              v-model="messageTxt"
              type="textarea"
              @keydown.enter="handleKeyCode($event)"
              placeholder="在此输入您想问的内容"
              :disabled="sending"
              ref="input"
            >
            </van-field>
            <van-button
              size="small"
              type="primary"
              :disabled="sending || messageTxt == ''"
              @click="onSend"
              >发送</van-button
            >
          </div>
          <div class="mobile-voice-input-wrap" v-else>
            <div class="text-btn" @click="changeTextOrVoice">
              <img src="../assets/keyboard.png" />
            </div>
            <van-button
              @touchstart="recordBtnTouchStart"
              @touchend="recordBtnTouchEnd"
              @touchmove="recordBtnMove"
              class="longtclick-btn"
              :disabled="sending"
            >
              按住说话
            </van-button>
          </div>
        </div>
        <div class="input-wrap" v-else>
          <van-field
            v-model="messageTxt"
            type="textarea"
            @keydown.enter="handleKeyCode($event)"
            placeholder="在此输入您想问的内容，Shift+Enter换行"
            :disabled="sending"
            ref="input"
          >
          </van-field>
          <div class="btn-wrap">
            <div
              v-if="isRecording == 0"
              class="record-btn"
              @click="startPCRecognition"
            >
              <img src="../assets/microphonepng.png" />
            </div>
            <template v-else>
              <div class="wave-box" v-if="isRecording == 2">
                <canvas id="canvas" width="320" height="120"></canvas>
              </div>
              <van-icon
                name="stop-circle-o"
                class="record-stop-btn"
                @click="stopRecord"
              />
            </template>
            <van-button
              size="small"
              type="primary"
              :disabled="sending || messageTxt == '' || isRecording > 0"
              @click="onSend"
              >发送</van-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
class FatalError extends Error {}
import { mapActions, mapGetters } from "vuex";
import * as api from "@/api";
import config from "@/config/config";
import mxinxData from "@/mixins/emojis";
import Header from "@/components/Header";
import SessionList from "@/components/SessionList";
import MessageFeedbackForm from "@/components/MessageFeedbackForm";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  showToast,
  showSuccessToast,
  showImagePreview,
  showConfirmDialog,
} from "vant";
import { debounce } from "@/utils/utils";
import axios from "@/api/axios";
import Recorder from "@/utils/js-audio-recorder.js";
//初始化录音实例
let recorder = null;
let interval = null;
//定义ws的相关参数
// let appkey = "";
// let token = "";
let websocket = null; //websocket实例
let timer_websocket = null; //websocket定时器, 用于实时获取语音转文本的结果
let websocket_task_id = null; //websocket任务id 整个实时语音识别的会话ID，整个请求中需要保持一致，32位唯一ID。

//波浪图-录音
let drawRecordId = null;
let soundWaveCanvas = null;
let soundWaveCtx = null;

export default {
  name: "Home",
  components: {
    Header,
    SessionList,
    MessageFeedbackForm,
  },
  mixins: [mxinxData],
  data() {
    return {
      pagePadding: true,
      isMobile: false,
      welcomeMsg: [],
      messages: [],
      messagePage: 0,
      messagePageSize: 20,
      messageTotal: 0,
      sending: false,
      currentSessionId: 0,
      messageTxt: "",
      controller: new AbortController(),
      isStopped: false,
      lastUpdatedSession: null,
      debounceHandleScroll: undefined,
      debounceScrollToBottom: undefined,
      feedbackMsgId: -1,
      avatarFixed: false,

      //阿里云语音识别接口key
      anls_token: "",
      anls_appkey: "",

      loop: null,
      longClick: 0,
      textOrVoice: 0, // 0:文本  1:语音
      isRecording: 0,
      cancelOrEditWhenRecording: 0, // 1:取消  2:编辑
      websocket_audio2txt_result_msg: null,
      websocket_audio2txt_result_msg_temp: null, //websocket实例 音频转文字的中间结果
    };
  },
  computed: {
    ...mapGetters(["userInfo", "authToken", "botCode", "botInfo"]),
  },
  mounted() {
    window.wxLinkClick = this.onClickWxLinkSend;
  },
  created() {
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth;
      window.screenWidth > 680
        ? (this.isMobile = false)
        : (this.isMobile = true);
    };
    window.onresize();
    this.currentSessionId =
      window.localStorage.currentSessionId || null
        ? parseInt(window.localStorage.currentSessionId)
        : 0;
    this.getWelcomeMsg().then(() => {
      if (this.currentSessionId > 0) {
        this.getMessageList(this.currentSessionId).then(() => {
          this.focusInput();
        });
      } else {
        this.pagePadding = false;
        this.focusInput();
      }
      api.getAlnsToken().then((res) => {
        this.anls_appkey = res.appkey;
        this.anls_token = res.token;
        // this.initWebSocket();
      });
    });
    this.debounceHandleScroll = debounce(this.handleScroll, 200);
    this.debounceScrollToBottom = debounce(this.scrollToBottom, 200);
  },
  unmounted() {
    this.isStopped = true;
    this.controller.abort();
  },
  methods: {
    ...mapActions(["setToken"]),
    getWelcomeMsg() {
      return api.getWelcomeMsg().then((res) => {
        this.welcomeMsg = res;
      });
    },
    onClickWxLinkSend(text) {
      console.log("onClickWxLinkSend", text);
      if (!text || text == "" || this.sending) {
        return;
      }
      this.sendMsg(text);
    },
    handleKeyCode(e) {
      if (e.keyCode == 13) {
        if (!this.isMobile && !e.shiftKey) {
          e.cancelBubble = true; //ie阻止冒泡行为
          e.stopPropagation(); //Firefox阻止冒泡行为
          e.preventDefault(); //取消事件的默认动作*换行
          this.sendMsg(this.messageTxt);
        }
      }
    },
    cancel(i) {
      this.isStopped = true;
      this.controller.abort();
      this.messages.splice(i, 1);
      this.sending = false;
    },
    onSend() {
      if (!this.sending && this.messageTxt != "") {
        this.sendMsg(this.messageTxt);
      }
    },

    // sendMsg(ask) {
    //   if (this.sending || ask == "") {
    //     return false;
    //   }
    //   let that = this;
    //   this.sending = true;
    //   let messages = [...this.messages];
    //   if (this.messages.some((item) => item.pendding === true)) {
    //     messages[messages.length - 1].askText = ask;
    //   } else {
    //     messages.push({
    //       askText: ask,
    //       pendding: true,
    //     });
    //   }
    //   this.messages = [...messages];
    //   this.sending = true;
    //   this.messageTxt = "";
    //   this.debounceScrollToBottom();
    //   let url = "WebChat/Chat";
    //   this.controller = new AbortController();
    //   this.isStopped = false;
    //   axios({
    //     loadingflag: false,
    //     url,
    //     method: "post",
    //     data: { query: ask, sessionId: this.currentSessionId },
    //     timeout: 0,
    //     onDownloadProgress: (progress) => {
    //       if (progress.event.target.status == 200) {
    //         let txt = progress.event.target.responseText;
    //         console.log(txt);
    //         if (txt != '{"') {
    //           txt = txt.replace(/\{[\s\n]*"answer(":([\s\n]*")*)*/g, "");
    //           txt = txt.replace(
    //             /"[\s\n\{\}]*[,，][\s\n]*"books(":[\s\n]*\[)*[\s\S]*/g,
    //             ""
    //           );
    //           if (txt.endsWith('","') || txt.endsWith('","')) {
    //             txt = txt.substr(0, txt.length - 3);
    //           }
    //           if (txt != "") {
    //             let lastMessage = messages[messages.length - 1];
    //             lastMessage.responses = [
    //               {
    //                 answerType: 0,
    //                 textContent: txt,
    //               },
    //             ];
    //             messages[messages.length - 1] = lastMessage;
    //             this.messages = [...messages];
    //             this.debounceScrollToBottom();
    //           }
    //         }
    //       }
    //     },
    //     signal: this.controller.signal,
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       let array_res = response.data.split("__completed__");
    //       let _res = array_res[array_res.length - 1];
    //       let res = JSON.parse(_res);
    //       that.lastUpdatedSession = {
    //         sessionId: res.sessionId,
    //         askText: ask,
    //       };
    //       if (that.currentSessionId == 0) {
    //         that.currentSessionId = res.sessionId;
    //         window.localStorage.currentSessionId = res.sessionId;
    //       }
    //       messages[messages.length - 1] = res;
    //       this.messages = [...messages];
    //       this.sending = false;
    //       this.focusInput();
    //       this.debounceScrollToBottom();
    //     })
    //     .catch((error) => {
    //       this.sending = false;
    //       this.messages.splice(-1, 1);
    //       console.log(error);
    //       this.focusInput();
    //       if (!that.isStopped) {
    //         showConfirmDialog({
    //           title: "糟糕了",
    //           message: "网络连接好像断开了，是否重新发送？",
    //           className: "custom-confirm-dialog",
    //           confirmButtonText: "重试",
    //         })
    //           .then(() => {
    //             that.sendMsg(ask);
    //           })
    //           .catch(() => {});
    //       }
    //     });
    // },

    sendMsg(ask) {
      if (this.sending || ask == "") {
        return false;
      }
      let that = this;
      this.isRecording = 0;
      this.sending = true;
      let messages = [...this.messages];
      if (this.messages.some((item) => item.pendding === true)) {
        messages[messages.length - 1].askText = ask;
      } else {
        messages.push({
          askText: ask,
          pendding: true,
        });
      }
      this.messages = [...messages];
      this.sending = true;
      this.messageTxt = "";
      this.debounceScrollToBottom();
      let url = `${config.apiBaseUrl}/WebChat/Chat`;
      let auth_token = this.authToken ? this.authToken.auth_token : "";
      let bot_code = this.botCode ? this.botCode : "";
      this.controller = new AbortController();
      this.isStopped = false;
      fetchEventSource(url, {
        method: "post",
        signal: this.controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth_token,
          bot_code: bot_code,
        },
        openWhenHidden: true,
        body: JSON.stringify({ query: ask, sessionId: this.currentSessionId }),
        async onopen(response) {
          console.log(response);
          if (
            response.ok &&
            response.headers.get("content-type") === "text/event-stream"
          ) {
            return; // everything's good
          } else if (response.status == 401) {
            api
              .refreshToken(that.userInfo.id, that.authToken.refresh_token)
              .then((res) => {
                that.setToken(res);
                that.sending = false;
                that.sendMsg(ask);
              });
            return;
          } else if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
          ) {
            // throw new FatalError();
            return;
          } else {
            throw new Error(response.status);
          }
        },
        onmessage(msg) {
          var result = JSON.parse(msg.data);
          if (msg.event === "FatalError") {
            throw new FatalError(result.message);
          }
          if (msg.event != "close") {
            result.pendding = true;
            let responses =
              result.responses &&
              result.responses.map((item) => {
                if (item.answerType == 0) {
                  let txt = item.textContent;
                  txt = txt.replace(/\{[\s\n]*"answer(":([\s\n]*")*)*/g, "");
                  txt = txt.replace(
                    /"[\s\n\{\}]*[,，][\s\n]*"books(":[\s\n]*\[)*[\s\S]*/g,
                    ""
                  );
                  if (txt.startsWith("{")) {
                    txt = txt.substr(1, txt.length - 1);
                  }
                  if (txt.endsWith('","') || txt.endsWith('","')) {
                    txt = txt.substr(0, txt.length - 3);
                  }
                  item.textContent = txt;
                }
                return item;
              });
            messages[messages.length - 1] = result;
            that.messages = [...messages];
          } else {
            console.log("close");
            that.lastUpdatedSession = {
              sessionId: result.sessionId,
              askText: ask,
            };
            if (that.currentSessionId == 0) {
              that.currentSessionId = result.sessionId;
              window.localStorage.currentSessionId = result.sessionId;
            }
            result.pendding = false;
            messages[messages.length - 1] = result;
            that.messages = [...messages];
            that.sending = false;
            that.controller.abort();
            that.focusInput();
          }
          that.debounceScrollToBottom();
        },
        onerror(err) {
          that.sending = false;
          that.messages.splice(-1, 1);
          console.log(err);
          that.focusInput();
          if (err instanceof FatalError) {
            showToast({
              message: err.message ? err.message : "出了点小问题，稍后再试",
              className: "custom-toast-text",
            });
          } else {
            if (!that.isStopped) {
              showConfirmDialog({
                title: "糟糕了",
                message: "网络连接好像断开了，是否重新发送？",
                className: "custom-confirm-dialog",
                confirmButtonText: "重试",
              })
                .then(() => {
                  that.sendMsg(ask);
                })
                .catch(() => {});
            }
          }
          throw err; //必须throw才能停止
        },
      });
    },
    focusInput() {
      if (this.isMobile) {
        return;
      }
      this.$nextTick(() => {
        this.$refs.input.$el.querySelector("textarea").focus();
      });
    },
    scrollToBottom(msgItem) {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#message-scroll-box");
        let top = container.scrollHeight;
        if (msgItem) {
          let msgItemDom = this.$el.querySelector(`#${msgItem}`);
          top = msgItemDom.offsetTop;
        }
        container.scrollTo({
          top: top,
          behavior: msgItem ? "instant" : "smooth",
        });
      });
    },
    handleScroll(event) {
      // 处理滚动事件
      this.$nextTick(() => {
        const scrollTop = event.target.scrollTop; // 获取滚动位置
        let welcomeMsgBox = this.$el.querySelector("#welcome-msg");
        let welcomeMsgH = welcomeMsgBox.offsetHeight;
        let avatarBox = this.$el.querySelector("#bot-avatar-box");
        if (scrollTop >= avatarBox.offsetTop + avatarBox.clientHeight) {
          this.avatarFixed = true;
        } else {
          this.avatarFixed = false;
        }
        if (scrollTop <= welcomeMsgH && !this.isLoading) {
          if (this.messageTotal > this.messages.length && !this.isLoading) {
            this.getMessageList(this.currentSessionId);
          }
        }
      });
    },
    getMessageList(sessionId) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      return api
        .getChatSessionDetail(
          sessionId,
          this.messagePage + 1,
          this.messagePageSize
        )
        .then((res) => {
          let scrollToMsgItem = null;
          if (this.messages.length > 0) {
            scrollToMsgItem = `msg_${this.messages[0].id}`;
          }
          let messages = [...this.messages];
          messages = res.data.concat(messages);
          this.messages = [...messages];
          this.messageTotal = res.total;
          this.messagePage += 1;
          this.pagePadding = false;
          this.debounceScrollToBottom(scrollToMsgItem);
          this.isLoading = false;
        });
    },
    newSession() {
      if (this.currentSessionId == 0) {
        return;
      }
      if (this.sending) {
        showToast({
          message: "接收消息中，请稍后再重试",
          className: "custom-toast-text",
        });
        return;
      }
      let that = this;
      that.currentSessionId = 0;
      that.messages = [];
      that.messagePage = 0;
      that.messageTotal = 0;
      that.sending = false;
      that.messageTxt = "";
      window.localStorage.removeItem("currentSessionId");
      // this.getWelcomeMsg();
      this.focusInput();
    },

    changeSession(sessionId) {
      if (this.currentSessionId == sessionId) {
        return;
      }
      if (this.sending) {
        showToast({
          message: "接收消息中，请稍后再重试",
          className: "custom-toast-text",
        });
        return;
      }
      let that = this;
      that.messages = [];
      that.messagePage = 0;
      that.messageTotal = 0;
      that.sending = false;
      window.localStorage.currentSessionId = sessionId;
      that.getMessageList(sessionId).then(() => {
        that.currentSessionId = sessionId;
      });
      this.messageTxt = "";
      this.focusInput();
    },
    imagePreview(url) {
      showImagePreview({
        images: [url],
        closeIcon: "close",
        closeable: !this.isMobile,
        className: "custom-image-preview",
      });
    },
    thumbUp(id, goodOrBad, i) {
      if (goodOrBad == 1) {
        api.msgThumbsCancel(id).then((res) => {
          let list = [...this.messages];
          list[i].goodOrBad = 0;
          this.messages = [...list];
          showSuccessToast({
            message: "感谢您的支持",
            className: "custom-toast-text",
            duration: 500,
          });
        });
      } else if (goodOrBad == 0) {
        api.msgThumbsUp(id).then((res) => {
          let list = [...this.messages];
          list[i].goodOrBad = 1;
          this.messages = [...list];
          showSuccessToast({
            message: "感谢您的支持",
            className: "custom-toast-text",
            duration: 500,
          });
        });
      }
    },
    thumbsDownSuccess(i) {
      let list = [...this.messages];
      list[i].goodOrBad = -1;
      this.messages = [...list];
      this.feedbackMsgId = -1;
      showSuccessToast({
        message: "感谢您的支持",
        className: "custom-toast-text",
        duration: 500,
      });
    },
    //移动端切换语音输入还是文字输入
    changeTextOrVoice() {
      let that = this;
      if (this.textOrVoice == 0) {
        // 获取麦克风权限
        Recorder.getPermission().then(
          () => {
            that.textOrVoice = 1;
            that.startRecord();
          },
          (error) => {
            showToast({
              message: "请先允许该网页使用麦克风",
              className: "custom-toast-text",
            });
            console.log(`${error.name} : ${error.message}`);
          }
        );
      } else {
        this.textOrVoice = 0;
      }
    },
    recordBtnTouchStart(event) {
      event.preventDefault(); //阻止浏览器默认行为
      let that = this;
      if (that.sending) {
        return;
      }
      this.longClick = 0;
      this.cancelOrEditWhenRecording = 0;
      // this.loop && clearTimeout(this.loop);
      // this.loop = setTimeout(() => {
      that.longClick = 1;
      // that.startRecord();
      that.startRecognition();
      // }, 200);
    },
    recordBtnTouchEnd(event) {
      let that = this;
      if (that.sending) {
        return;
      }
      this.loop && clearTimeout(this.loop);
      event.preventDefault(); //阻止浏览器默认行为
      let posEnd = event.changedTouches[0]; //获取终点
      if (this.longClick != 0) {
        that.stopRecord();
        let btnTop = event.target.getBoundingClientRect().top; //获取按钮距离顶部高度
        if (posEnd.pageY < btnTop) {
          //上划离开按钮
          let windowCenterX = document.body.clientWidth / 2; //屏幕中线位置
          if (posEnd.pageX > windowCenterX) {
            //编辑
            that.textOrVoice = 0;
          } else {
            //取消
          }
        } else {
          //没有离开按钮本身
          console.log("发送");
          if (this.messageTxt != "") {
            this.onSend();
          }
        }
      }
    },
    recordBtnMove(event) {
      // console.log("recordBtnMove");
      event.preventDefault(); //阻止浏览器默认行为
      let posMove = event.targetTouches[0]; //获取滑动实时坐标
      let btnTop = event.target.getBoundingClientRect().top; //获取按钮距离顶部高度
      if (posMove.pageY < btnTop) {
        //上划离开按钮
        let windowCenterX = document.body.clientWidth / 2; //屏幕中线位置
        if (posMove.pageX > windowCenterX) {
          //编辑
          this.cancelOrEditWhenRecording = 2;
        } else {
          //取消
          this.cancelOrEditWhenRecording = 1;
        }
      } else {
        this.cancelOrEditWhenRecording = 0;
      }
    },
    startPCRecognition() {
      let that = this;
      that.startRecord().then(() => {
        that.startRecognition();
      });
    },
    startRecord() {
      // 获取麦克风权限
      return new Promise((resolve, reject) => {
        if (!recorder) {
          recorder = new Recorder({
            sampleBits: 16, // 采样位数，，默认是16
            sampleRate: 16000, //音频采样率，默认是16000Hz，
            numChannels: 1, // 声道，支持 1 或 2， 默认是1
            compiling: true, // 是否边录边转换，默认是false
          });
          Recorder.getPermission().then(
            () => {
              recorder.start().then(
                () => {
                  console.log(`开始录音`);
                  resolve();
                },
                (error) => {
                  console.log(`出错了`);
                  reject(error);
                }
              );
            },
            (error) => {
              showToast({
                message: "请先允许该网页使用麦克风",
                className: "custom-toast-text",
              });
              console.log(`${error.name} : ${error.message}`);
            }
          );
        } else {
          resolve();
        }
      });
    },
    startRecognition() {
      console.log("开始识别", this.isRecording);
      if (this.isRecording != 0) {
        return;
      }
      let that = this;
      that.isRecording = 1;
      that.messageTxt = ""; //置空
      that.websocket_audio2txt_result_msg = "";
      that.websocket_audio2txt_result_msg_temp = "";
      recorder.clear();
      that.initWebSocket();
      // that.StartTranscription();
    },
    stopRecord() {
      // console.log("结束录音");
      // recorder.stop();
      recorder && recorder.clear();
      interval && clearInterval(interval);
      if (websocket !== null) {
        this.websocketSendStop();
        websocket.close();
        websocket = null;
      }
      drawRecordId && cancelAnimationFrame(drawRecordId);
      drawRecordId = null;
      this.isRecording = 0;
    },
    initWebSocket() {
      console.log("initWebSocket", this.isRecording);
      if (this.isRecording == 0) {
        return;
      }
      // console.log("初始化weosocket");
      //检测如果未关闭、则先关闭在重连
      if (websocket !== null) {
        websocket.close();
        websocket = null;
      }

      //ali的websocket地址
      const wsuri = `wss://nls-gateway.cn-shanghai.aliyuncs.com/ws/v1?token=${this.anls_token}`;

      //连接wss服务端
      websocket = new WebSocket(wsuri);
      //指定回调函数
      websocket.onopen = this.websocketOnOpen;
      websocket.onmessage = this.websocketOnMessage;
      websocket.onerror = this.websocketOnError;
      websocket.onclose = this.websocketClose;
    },
    websocketOnOpen() {
      console.log("websocketOnOpen");
      if (this.isRecording == 0) {
        return;
      }
      let that = this;
      //生成新的任务id
      websocket_task_id = this.getRandomStrNum();
      //生成ali的请求参数message_id
      let message_id = this.getRandomStrNum();
      let actions = {
        header: {
          namespace: "SpeechTranscriber", //固定值
          name: "StartTranscription", //发送请求的名称，固定值
          appkey: "xHycX1NBUEHSwlRl", //that.anls_appkey, //appkey
          message_id: message_id, //消息id
          task_id: websocket_task_id, //任务id
        },
        payload: {
          format: "PCM", //音频编码格式，默认是PCM（无压缩的PCM文件或WAV文件），16bit采样位数的单声道。
          sample_rate: 16000, //需要与录音采样率一致、默认是16000，单位是Hz。
          enable_intermediate_result: true, //是否返回中间识别结果，默认是false。
          enable_punctuation_prediction: true, //是否在后处理中添加标点，默认是false。
          enable_inverse_text_normalization: false, //是否在后处理中执行数字转写，默认是false。
          max_sentence_silence: 800, //	语音断句检测阈值，静音时长超过该阈值会被认为断句，参数范围200ms～2000ms，默认值800ms。
          vocabulary_id: "df478404649340dbbe9f6b946888e072", //POP API创建业务专属泛热词ID
        },
      };

      //发送请求
      this.websocketSend(JSON.stringify(actions));
      if (timer_websocket) {
        console.log("clear_timer");
        clearTimeout(timer_websocket);
        timer_websocket = null;
      }
      timer_websocket = setTimeout(() => {
        that.stopRecord();
      }, 20000);
    },
    //发送数据
    websocketSend(data) {
      //判断是否连接成功,连接成功再发送数据过去
      if (websocket.readyState === 1) {
        websocket.send(data);
      } else {
        console.log("websock未连接-------------------");
      }
    },
    //接收数据
    websocketOnMessage(e) {
      if (this.isRecording == 0) {
        websocket && websocket.close();
        websocket = null;
        return;
      }
      let that = this;
      //接受ali 语音返回的数据
      const ret = JSON.parse(e.data);

      //判断返回的数据类型
      if (ret.header.name === "TranscriptionResultChanged") {
        //数据在收集中 一句话的中间结果
        // console.log("数据在收集中", ret.payload.result);
        //实时获取语音转文本的结果
        that.ingText(ret.payload.result);
      } else if (ret.header.name === "SentenceBegin") {
        //一句话开始后，就可以启动录音了
        // console.log("检测到了一句话的开始");
        that.websocket_audio2txt_result_msg_temp = "";
      } else if (ret.header.name === "TranscriptionStarted") {
        console.log("服务端已经准备好了进行识别，客户端可以发送音频数据了");
        that.isRecording = 2;
        that.$nextTick(() => {
          //录音波浪
          soundWaveCanvas = document.getElementById("canvas");
          soundWaveCtx = soundWaveCanvas.getContext("2d");
          that.drawRecord(); //开始绘制图片
        });
        //获取音频信息，定时获取并发送
        interval = setInterval(() => {
          that.getPCMAndSend();
        }, 200);
      } else if (ret.header.name === "SentenceEnd") {
        // console.log("数据接收结束", ret);
        that.endText(ret.payload.result);
      } else if (ret.header.name === "TranscriptionCompleted") {
        console.log("服务端已停止了语音转写", ret);
      }
    },
    //错误处理
    websocketOnError(e) {
      console.log("连接建立失败重连");
      //initWebSocket();
    },
    //关闭处理
    websocketClose(e) {
      console.log("websocketClose断开连接", e);
    },
    //wss 连接建立之后发送 StopTranscription指令
    websocketSendStop() {
      console.log("向  websocket 发送 Stop指令");
      let message_id = this.getRandomStrNum();
      //actions 是首次连接需要的参数,可自行看阿里云文档
      let actions = {
        header: {
          message_id: message_id,
          task_id: websocket_task_id,
          namespace: "SpeechTranscriber",
          name: "StopTranscription",
          appkey: this.anls_appkey,
        },
      };

      //发送结束指令
      this.websocketSend(JSON.stringify(actions));
    },
    //获取音频信息，并发送
    getPCMAndSend() {
      let that = this;
      //获取音频信息
      let NextData = recorder.getNextData();
      let blob = new Blob([NextData]);
      let blob_size = blob.size;
      // console.log("获取音频信息，并发送,blob_size:" + blob_size, blob);

      //ali最大支持3200字节的音频
      let max_blob_size = 3200; //支持1600 或3200
      let my_num = blob_size / max_blob_size;
      my_num = my_num + 1;

      //切分音频发送
      for (let i = 0; i < my_num; i++) {
        var end_index_blob = max_blob_size * (i + 1);
        //判断结束时候的分界
        if (end_index_blob > blob_size) {
          end_index_blob = blob_size;
        }
        //切分音频
        var blob2 = blob.slice(i * max_blob_size, end_index_blob);
        //生成新的blob
        const newbolb = new Blob([blob2], { type: "audio/pcm" });
        let blob2_size = newbolb.size;
        //发送
        that.websocketSend(newbolb);
      }
    },
    ingText(text) {
      // //更新中间变化状态
      this.websocket_audio2txt_result_msg_temp = text;
      this.messageTxt =
        this.websocket_audio2txt_result_msg +
        this.websocket_audio2txt_result_msg_temp;
      if (timer_websocket) {
        clearTimeout(timer_websocket);
        timer_websocket = null;
      }
    },

    //设置定时器-websocket 实时获取语音转文本的结果
    endText(text) {
      let that = this;
      // //更新最后的识别结果
      this.websocket_audio2txt_result_msg_temp = "";
      //获取全文
      this.websocket_audio2txt_result_msg += text;
      this.messageTxt = this.websocket_audio2txt_result_msg;
      if (timer_websocket) {
        console.log("clear_timer");
        clearTimeout(timer_websocket);
        timer_websocket = null;
      }
      timer_websocket = setTimeout(() => {
        that.stopRecord();
      }, 20000);
    },

    //生成32位随机数UUID
    getRandomStrNum() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23];

      var uuid = s.join("");
      return uuid;
    },
    /**
     * 绘制波浪图-录音
     * */
    drawRecord() {
      // 用requestAnimationFrame稳定60fps绘制
      drawRecordId = requestAnimationFrame(this.drawRecord);

      // 实时获取音频大小数据
      let dataArray = recorder.getRecordAnalyseData(),
        bufferLength = dataArray.length;

      // 填充背景色
      soundWaveCtx.fillStyle = "#ffffff";
      soundWaveCtx.fillRect(
        0,
        0,
        soundWaveCanvas.width,
        soundWaveCanvas.height
      );

      // 设定波形绘制颜色
      soundWaveCtx.lineWidth = 2;
      soundWaveCtx.strokeStyle = "#1989fa";

      soundWaveCtx.beginPath();
      // var sliceWidth = (this.oCanvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
      //   x = 0; // 绘制点的x轴位置
      // for (var i = 0; i < bufferLength; i++) {
      //   var v = dataArray[i] / 128.0;
      //   var y = (v * this.oCanvas.height) / 2;
      //   if (i === 0) {
      //     // 第一个点
      //     this.ctx.moveTo(x, y);
      //   } else {
      //     // 剩余的点
      //     this.ctx.lineTo(x, y);
      //   }
      //   // 依次平移，绘制所有点
      //   x += sliceWidth;
      // }

      // this.ctx.lineTo(this.oCanvas.width, this.oCanvas.height / 2);
      // this.ctx.stroke();

      let sliceWidth = (soundWaveCanvas.width * 1.0) / 24; //一个点占多少位置，共有32个点
      soundWaveCtx.lineWidth = sliceWidth / 2;
      let x = sliceWidth / 2;
      let v = 0;
      for (let i = 0; i < bufferLength; i++) {
        if (i % 24 === 0 && i > 0) {
          let h = (v / 128.0) * (soundWaveCanvas.height - 10) + 10;
          let y = (soundWaveCanvas.height - h) / 2;
          soundWaveCtx.moveTo(x, y);
          soundWaveCtx.lineTo(x, y + h);
          v = 0;
          x += sliceWidth;
        } else {
          soundWaveCtx.moveTo(x, (soundWaveCanvas.height - 10) / 2);
          soundWaveCtx.lineTo(x, (soundWaveCanvas.height - 10) / 2 + 10);
        }
        let _v = Math.abs(dataArray[i] - 128);
        if (_v > v) {
          v = _v;
        }
      }
      soundWaveCtx.stroke();
    },
  },
};
</script>
<style lang="less">
@media screen and (min-width: 680px) {
  .custom-image-preview {
    & .van-icon {
      position: absolute;
      font-size: 32px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 32px;

      &.van-image-preview__close-icon--top-right {
        top: 16px;
        right: 16px;
        position: absolute;
      }
    }
  }
  .van-dialog.custom-confirm-dialog {
    font-size: 16px;
    width: 20em;
    border-radius: 16px;
    & .van-dialog__header {
      padding-top: 20px;
      line-height: 1.5em;
    }
    & .van-dialog__message {
      padding: 10px 20px 24px;
      font-size: 16px;
      line-height: 1.5em;
    }
    & .van-dialog__message--has-title {
      padding-top: 16px;
    }
    & .van-button {
      font-size: 16px;
      height: 3em;
      line-height: 3em;
      padding: 0 0.5em;
    }
  }
  .van-toast.custom-toast-text {
    font-size: 16px;
    padding: 12px;
    width: 120px;
    min-height: 120px;
    line-height: 1.5em;
  }
}
</style>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  & .top-bot-avatar {
    position: absolute;
    top: -120px;
    left: 50%;
    z-index: 9;
    margin: 0 0 0 -40px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    transition: top 0.3s;
    animation-name: jump;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background: #fff;
    // border: 4px solid #f7f8fc;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    & img {
      width: 100%;
    }
    &.show {
      top: 0;
    }
    &.stop {
      animation: none !important;
    }
  }
  &-main {
    display: flex;
    flex: 1;
    background: #fff;
    overflow: hidden;

    &-content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #f7f8fc;
      overflow: hidden;
      & .message-scroll-box {
        padding: 0;
        flex: 1;
        overflow-y: auto;
        width: 100%;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        // touch-action: pan-y;
        overscroll-behavior: none;
        touch-action: manipulation;
        & .message-list {
          & .bot-avatar-box {
            height: 200px;
            overflow: hidden;
            & .bot-avatar {
              width: 120px;
              height: 120px;
              border-radius: 50%;
              overflow: hidden;
              margin: 60px auto 20px;
              animation-name: jump;
              animation-duration: 0.6s;
              animation-iteration-count: infinite;
              animation-direction: alternate;
              background: #fff;
              // border: 4px solid #f7f8fc;
              box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
              & img {
                width: 100%;
              }
              &.stop {
                margin: 40px auto 20px;
                animation: none !important;
              }
            }
          }
          box-sizing: border-box;
          padding: 0;
          background: #fff;
          & .chat-item-wrap {
            background: #f7f8fc;
            margin: 4px 0;
            padding: 10px 0;
          }
          & .chat-item {
            padding: 15px 20px;
            -webkit-transition: opacity 0.5s;
            transition: opacity 0.5s;
            overflow: hidden;
            & .text {
              width: auto;
              max-width: 85%;
              display: inline-block;
              padding: 12px 16px;
              position: relative;
              font-size: 16px;
              word-wrap: break-word;
              word-break: normal;
              text-align: left;
              line-height: 1.5em;
              & .typer-cursor {
                display: inline-block;
                vertical-align: middle;
                width: 2px;
                height: 14px;
                margin-left: 4px;
                background-color: #409eff;
                animation: flash 1.6s linear infinite;
              }
              & .markdown {
                & /deep/ p {
                  padding: 12px 0;
                }
                & /deep/ h1 {
                  font-size: 1.3em;
                  font-weight: 600;
                  margin: 24px 0 16px;
                }
                & /deep/ h2 {
                  font-size: 1.25em;
                  font-weight: 600;
                  margin: 24px 0 16px;
                }
                & /deep/ h3 {
                  font-size: 1.2em;
                  font-weight: 600;
                  margin: 24px 0 16px;
                }
                & /deep/ ul {
                  margin-top: 0;
                  margin-bottom: 16px;
                  padding-left: 1.5em;
                  list-style-type: disc;
                }
                & /deep/ ol {
                  margin-top: 0;
                  margin-bottom: 16px;
                  padding-left: 1.2em;
                  list-style-type: decimal;
                }
                & /deep/ li {
                  margin: 6px 0;
                }
                & /deep/ img {
                  max-width: 100%;
                }
              }
            }
            & .image {
              width: auto;
              max-width: 60%;
              display: inline-block;
              border-radius: 8px;
              overflow: hidden;
              & .van-image {
                min-width: 56px;
                min-height: 56px;
                display: block;
                max-width: 100%;
              }
            }
            & .article {
              width: 80%;
              background: #fff;
              display: inline-block;
              border-radius: 5px;
              padding: 10px 15px;
              font-size: 16px;
              &-title {
                margin-bottom: 10px;
                text-align: left;
              }
              &-description {
                color: #999;
                font-size: 14px;
                display: flex;
                text-align: left;
                & span {
                  flex: 1;
                  overflow: hidden;
                }
                & .van-image {
                  margin-left: 10px;
                  width: 60px;
                  height: 60px;
                }
              }
            }

            &.body_right {
              text-align: right;
              .text {
                background: #409eff;
                color: #fff;
                border-radius: 16px 0 16px 16px;
              }
            }

            &.body_left {
              text-align: left;

              & .text {
                background: #fff;
                color: #3f3f3f;
                text-align: left;
                border-radius: 0 16px 16px 16px;
                padding: 0 16px;
              }
            }
            & span.spinner {
              padding-top: 13px;
              width: 100px;
              text-align: center;

              & > i {
                width: 10px;
                height: 10px;
                background-color: #999;
                border-radius: 100%;
                display: inline-block;
                -webkit-animation: bouncedelay 1.6s infinite ease-in-out;
                animation: bouncedelay 1.6s infinite ease-in-out;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
                margin: 0 3px;
              }
              & .bounce1 {
                -webkit-animation-delay: -0.42s;
                animation-delay: -0.42s;
              }
              & .bounce2 {
                -webkit-animation-delay: -0.24s;
                animation-delay: -0.24s;
              }
            }
          }
          & .chat-footer {
            border-top: 1px solid #efefef;
            padding: 4px 20px 0;
            font-size: 18px;
            margin-top: 15px;
            & .van-button {
              padding: 0 12px;
            }
            & .van-button--mini {
              font-size: 18px;
            }
            & .van-button--mini + .van-button--mini {
              margin-left: 10px;
            }
            & .no-good :deep(.van-icon) {
              transform: scaleY(-1);
            }
            & :deep(.van-popover__wrapper) {
              margin-left: 10px;
            }
          }
        }
      }
      & .speech-recognition-wrap {
        height: 100%;
        width: 100%;
        position: fixed;
        box-sizing: border-box;
        top: 0;
        padding-bottom: 68px;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        & .recognition-result-wrap {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & .recognition-result-box {
            background: #fff;
            border-radius: 8px;
            width: 300px;
            padding: 16px 16px 0;
            text-align: center;
            // & .van-loading {
            //   text-align: center;
            // }
            & :deep(.van-loading__spinner) {
              width: 18px;
              height: 18px;
              margin-bottom: 16px;
            }
            & .recognition-result-text {
              text-align: left;
              font-size: 16px;
              line-height: 1.5em;
            }
            & canvas {
              width: 80px;
              height: 30px;
            }
          }
        }
        & .cancel-btn-wrap {
          display: flex;
          justify-content: space-around;
          padding-bottom: 40px;
          color: #fff;
          font-size: 14px;
          & .btn-item {
            text-align: center;
            & p {
              height: 18px;
              line-height: 18px;
              padding-bottom: 6px;
            }
            & .van-icon {
              width: 60px;
              height: 60px;
              background: rgba(0, 0, 0, 0.5);
              border-radius: 50%;
              font-size: 28px;
              line-height: 60px;
            }
            &.active {
              & .van-icon {
                background: #409eff;
              }
            }
          }
        }
        & .tips {
          color: #fff;
          font-size: 12px;
          text-align: center;
          padding-bottom: constant(safe-area-inset-bottom);
          padding-bottom: env(safe-area-inset-bottom);
        }
      }
      & .mobile-input-wrap {
        width: 100%;
        position: relative;
        background: #efefef;
        & .mobile-text-input-wrap {
          position: relative;
          & .record-btn {
            cursor: pointer;
            position: absolute;
            z-index: 9;
            left: 0;
            width: 42px;
            top: 50%;
            margin-top: -12px;
            text-align: center;
            & img {
              height: 24px;
            }
          }
          & .van-field {
            height: 58px;
            overflow: hidden;
            padding: 0 62px 0 26px;
            background: #efefef;
            border-radius: 0;
            & :deep(.van-cell__value) {
              padding: 8px 12px;
              font-size: 16px;
            }
            & :deep(.van-field__control) {
              height: 42px;
              min-height: 42px;
              line-height: 1.5em;
              background: #fff;
              border-radius: 6px;
              padding: 4px 6px;
              box-sizing: border-box;
            }
          }
          & .van-button {
            position: absolute;
            right: 12px;
            top: 50%;
            margin-top: -15px;
            font-size: 14px;
            line-height: 32px;
            height: 32px;
            padding: 0 10px;
            border-radius: 5px;
          }
        }
        & .mobile-voice-input-wrap {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          & .text-btn {
            padding-right: 8px;
            & img {
              width: 20px;
            }
          }
          & .longtclick-btn {
            flex: 1;
            height: 42px;
            line-height: 42px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.van-popover[data-popper-placement^="right-end"] {
  & :deep(.van-popover__arrow) {
    border-width: 6px;
    margin-left: -6px;
    border-left-width: 0;
    bottom: 8px;
  }
}

@media screen and (min-width: 680px) {
  .home {
    & .top-bot-avatar {
      top: -120px;
      z-index: 999;
      margin: 0 0 0 86px;
      width: 80px;
      height: 80px;
      &.show {
        top: 0;
      }
    }
    &-main {
      padding: 16px 16px 16px 0;
      background: #fff;

      &-side {
        width: 272px;
        // background: #1989fa;
        box-sizing: border-box;
        & .new-session-btn {
          width: 240px;
          margin: 0 16px 16px;
        }
      }
      &-content {
        border-radius: 32px;
        background: #f0f1f6;
        & .message-scroll-box {
          padding: 0;
          background: transparent;
          & .message-list {
            & .bot-avatar-box {
              height: 200px;
              & .bot-avatar {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                margin: 60px auto 20px;
                // border: 4px solid #f7f8fc;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
                &.stop {
                  margin: 40px auto 20px;
                }
              }
            }
            margin: 0 auto;
            width: 65%;
            max-width: 1000px;
            min-width: 408px;
            padding: 0;
            background: transparent;
            & .chat-item-wrap {
              margin: 10px 0;
              padding: 10px 0;
              background: #f7f8fc;
            }
            & .chat-item {
              padding: 15px 20px;
              margin: 4px 0;
              & .text {
                max-width: 85%;
                padding: 12px 16px;
                font-size: 14px;
                line-height: 1.5em;
                & .typer-cursor {
                  width: 2px;
                  height: 14px;
                  margin-left: 4px;
                }
              }
              & .image {
                border-radius: 8px;
                & .van-image {
                  min-width: 56px;
                  min-height: 56px;
                }
              }
              & .article {
                width: 60%;
                border-radius: 5px;
                padding: 10px 15px;
                font-size: 16px;
                &-title {
                  margin-bottom: 10px;
                }
                &-description {
                  font-size: 14px;
                  & .van-image {
                    margin-left: 10px;
                    width: 60px;
                    height: 60px;
                  }
                }
              }

              &.body_right {
                .text {
                  border-radius: 16px 0 16px 16px;
                }
              }

              &.body_left {
                & .text {
                  border-radius: 0 16px 16px 16px;
                  padding: 0 16px;
                  & .markdown {
                    & /deep/ p {
                      padding: 12px 0;
                    }
                    & /deep/ h1 {
                      font-size: 1.3em;
                      font-weight: 600;
                      margin: 24px 0 16px;
                    }
                    & /deep/ h2 {
                      font-size: 1.25em;
                      font-weight: 600;
                      margin: 24px 0 16px;
                    }
                    & /deep/ h3 {
                      font-size: 1.2em;
                      font-weight: 600;
                      margin: 24px 0 16px;
                    }
                    & /deep/ ul {
                      margin-top: 0;
                      margin-bottom: 16px;
                      padding-left: 1.5em;
                      list-style-type: disc;
                    }
                    & /deep/ ol {
                      margin-top: 0;
                      margin-bottom: 16px;
                      padding-left: 1.2em;
                      list-style-type: decimal;
                    }
                    & /deep/ li {
                      margin: 6px 0;
                    }
                    & /deep/ img {
                      max-width: 50%;
                    }
                  }
                }
              }
              & span.spinner {
                padding-top: 13px;
                width: 100px;
                & > i {
                  width: 10px;
                  height: 10px;
                  margin: 0 3px;
                }
              }
            }
            & .chat-footer {
              padding: 4px 20px 0;
              font-size: 18px;
              margin-top: 15px;
              & .van-button {
                padding: 0 12px;
                line-height: 1.5em;
                height: 1.5em;
              }
              & .van-button--mini {
                font-size: 18px;
              }
              & .van-button--mini + .van-button--mini {
                margin-left: 10px;
              }
              & :deep(.van-popover__wrapper) {
                margin-left: 10px;
              }
            }
          }
        }
        & .input-wrap {
          width: 65%;
          max-width: 1000px;
          min-width: 408px;
          padding-bottom: 18px;
          position: relative;
          & .van-field {
            height: 82px;
            width: 100%;
            padding: 0;
            overflow: hidden;
            border-radius: 10px;
            & :deep(.van-cell__value) {
              padding: 10px 75px 10px 15px;
              font-size: 14px;
            }
            & :deep(.van-field__control) {
              height: 62px;
              min-height: 62px;
              line-height: 1.5em;
            }
          }
          & .btn-wrap {
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -26px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            & .wave-box {
              background: #fff;
              padding: 0 12px;
              & canvas {
                height: 30px;
                width: 80px;
              }
            }
            & .record-btn {
              cursor: pointer;
              margin-right: 12px;
              & img {
                height: 30px;
              }
            }
            & .record-stop-btn {
              font-size: 28px;
              line-height: 30px;
              margin-right: 12px;
              color: #409eff;
            }
            & .van-button {
              font-size: 16px;
              line-height: 32px;
              height: 32px;
              padding: 0 10px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
  & :deep(.van-popover__wrapper) {
    margin-left: 10px;
  }
  & .van-popover[data-popper-placement^="right-end"] {
    & :deep(.van-popover__arrow) {
      border-width: 6px;
      margin-left: -6px;
      border-left-width: 0;
      bottom: 8px;
    }
    & :deep(.van-popover__content) {
      border-radius: 8px;
    }
  }
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.4);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0.4);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes flash {
  0%,
  80%,
  100% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}
@keyframes flash {
  0%,
  80%,
  100% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}
@keyframes jump {
  to {
    transform: translateY(-40px);
  }
}
@-webkit-keyframes jump {
  to {
    transform: translateY(-40px);
  }
}
</style>
